



























export default {
  name: "TbButton",
  props: ["buttonText", "extraClasses"],
  methods: {
      testFunction: function() {
        console.log('test clicked')
    }
  }
};
