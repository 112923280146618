










































































































import { vxm } from "@/store";
import { Component, Vue, Prop } from "vue-property-decorator";
import { ProfileDTO } from "@shared_vue/openapi/myvaultapi";
import { CaseDTO } from "@shared_vue/openapi/caseapi";
import { OptionDTO } from "@shared_vue/openapi/myvaultapi";
import TbButton from "@/views/TbButton.vue";

@Component({
  components: {
    TbButton
  },
})
export default class Step1 extends Vue {
  @Prop() readonly person: ProfileDTO | undefined;
  @Prop() readonly realCase: CaseDTO | undefined;
  @Prop() readonly caseCategory: OptionDTO[] | undefined;

  private ui = vxm.ui;
  private caseStore = vxm.case;
  private publicPath = process.env.VUE_APP_STATIC_DIR;
  private selectedCategory: any = 0;
  private auth = vxm.auth;

  get DisplayCategoryTypes() {
    return this.caseCategory!.map((o) => ({ value: o.id, label: o.text }));
  }

  get name() {
    return this.auth.usr?.displayName;
  }
}
